import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, enableProdMode, ErrorHandler, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {MsalInterceptor, MsalModule} from '@azure/msal-angular';
import {Router, RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MainPageModule} from './main-page/main-page.module';
import {ROUTES} from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IdTokenComponent } from './id-token/id-token.component';
import { AccessTokenComponent } from './access-token/access-token.component';
import { ModalWaitComponent } from './modal-wait/modal-wait.component';
import {PdfPreviewComponent} from './pdf-preview/pdf-preview.component';
import {PdfViewerModule} from './form-components/pdf-viewer/pdf-viewer.module';
import {PdfButtonsBarModule} from './form-components/pdf-buttons-bar/pdf-buttons-bar.module';
import {AuthInterceptor} from './interceptors/AuthInterceptor';
import * as Sentry from '@sentry/angular';
import {environment} from '../environments/environment';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';
import {MatButtonModule} from '@angular/material/button';

let api = '';
let clientId = '';
let authority = '';
let redirectUri = '';
if (environment.production) {
  api = 'api://bfb9e362-53ff-402f-a304-159bf44eccf6/token-api';
  clientId = '8be3ace8-7fb2-4020-9173-e0e0b03073a1';
  authority = 'https://login.microsoftonline.com/118b7cfa-a3dd-48b9-b026-31ff69bb738b';
  redirectUri = 'https://dss.easy-forward.com';
}
else {
  api = 'api://d7176004-8973-4cb9-b1a5-25354d5d3807/token-api';
  clientId = '9e9fc7d2-b797-4f95-9574-5ff0cb4324e4';
  authority = 'https://login.microsoftonline.com/46c5ba75-2fda-4229-a53f-5dae31c6c171';
  //redirectUri = 'https://manchester-hq-bvrtkbrtpbc.dynamic-m.com:4443';
  redirectUri = 'https://dev-dss.easy-forward.com';
}
console.log('environment.production = ' + environment.production);
console.log('redirectUri = ' + redirectUri);

@NgModule({
  declarations: [
    AppComponent,
    IdTokenComponent,
    AccessTokenComponent,
    ModalWaitComponent,
    PdfPreviewComponent,
    ModalDialogComponent
  ],
  imports: [
    BrowserModule,
    // AppRoutingModule,
    MsalModule.forRoot({
      auth: {
        clientId,
        authority,
        redirectUri
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true, // Set to true for Internet Explorer 11
      },
      system: {
        loadFrameTimeout: 30000
      }
    }, {
      popUp: false,
      consentScopes: [
        'user.read',
        api
      ],
      unprotectedResources: [],
      protectedResourceMap: [
        ['api/DocumentFormEntities', [api]],
        ['api/RecipientEntities', [api]],
        ['api/TransactionEntities', [api]],
        ['api/UserEntity', [api]],
        ['api/DocumentFormFieldEntities', [api]],
        ['api/BreEntities', [api]],
        ['api/DocumentPreview', [api]],
        ['api/RoleEntities', [api]],
        ['api/UserRoleEntities', [api]],
        ['api/TransactionDocumentEntities', [api]],
        ['api/OfficeEntities', [api]],
        ['api/AdditionalDocumentEntities', [api]]
      ],
      extraQueryParameters: {}
    }),
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      // preloadingStrategy: PreloadAllModules
    }),
    HttpClientModule,
    MainPageModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    PdfButtonsBarModule,
    MatTableModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatListModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [Router],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
