import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DocumentForm} from '../models/DocumentForm';
import {DocumentFormField} from '../models/DocumentFormField';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Recipient} from '../models/Recipient';
import {TransactionDocument} from '../models/TransactionDocument';
import {Transaction} from '../models/Transaction';
import {User} from '../models/User';
import {Role} from '../models/Role';
import {Bre} from '../models/Bre';
import {UserRole} from '../models/UserRole';
import {GlobalField} from '../models/GlobalField';
import {Office} from '../models/Office';
import {CountReport} from '../models/CountReport';

@Injectable()
export class DefaultApi {
  constructor(private http: HttpClient) {
  }
  getDocumentForms(): Observable<any>{
    const url = `/api/DocumentFormEntities`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(document => {
          return new DocumentForm({
            id: document.id,
            documentCode: document.documentCode,
            documentDescription: document.documentDescription,
            documentFormFields: document.documentFormFields,
            bre: document.bre,
            isLtss: document.isLtss,
            isQa: document.isQa
          });
        })
      ));
  }

  getRecipients(pageNumber, pageSize, firstName, lastName, address, city, state, zipCode): Observable<any> {
    const url = `/api/RecipientEntities/findRecipient?pageNumber=${pageNumber}&pageSize=${pageSize}&firstName=${firstName}&lastName=${lastName}&address=${address}&city=${city}&state=${state}&zipCode=${zipCode}`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(recipient => {
          return new Recipient({
            id: recipient.id,
            firstName: recipient.firstName,
            lastName: recipient.lastName,
            address: recipient.address,
            city: recipient.city,
            state: recipient.state,
            zipCode: recipient.zipCode,
            isPrimary: recipient.isPrimary
          });
        })
      ));
  }
  sendTransaction(transaction: Transaction): Observable<any> {
    const url = `/api/TransactionEntities`;
    return this.http.post(url, transaction);
  }

  getDocumentForm(id: any): Observable<any> {
    const url = `/api/DocumentFormEntities/${id}`;
    return this.http.get(url);
  }

  getDocumentPreview(document): Observable<any> {
    const url = `/api/DocumentPreview/getDocumentPreview`;
    return this.http.post(url, document, {responseType: 'blob'});
  }

  saveDocument(documentForm: DocumentForm, file): Observable<any> {
    const url = `/api/DocumentFormEntities`;
    const formData = new FormData();
    formData.append('file', file);
    const form = {
      Id: documentForm.id,
      DocumentCode: documentForm.documentCode,
      DocumentDescription: documentForm.documentDescription,
      isLtss: documentForm.isLtss,
      isQa: documentForm.isQa,
      Bre: {
        Id: documentForm.bre.id,
        BreCode: documentForm.bre.breCode,
        BreDescription: documentForm.bre.breDescription
      }
    };
    formData.append('documentForm', JSON.stringify(documentForm));
    return this.http.post(url, formData);
  }
  deleteDocument(id): Observable<any> {
    const url = `/api/DocumentFormEntities/${id}`;
    return this.http.delete(url);
  }

  deleteDocField(docFieldId): Observable<any> {
    const url = `/api/DocumentFormFieldEntities/deleteField/${docFieldId}`;
    return this.http.delete(url);
  }

  postDocField(documentField): Observable<any> {
    const url = `/api/DocumentFormFieldEntities/DocumentFormFieldEntity`;
    return this.http.post(url, documentField);
  }

  getUserInfo(): Observable<any> {
    const url = `/api/UserEntity/user`;
    return this.http.get(url)
      .pipe(map((value: any) => this.buildUser(value)
      ));
  }

  getUsers(): Observable<any> {
    const url = `/api/UserEntity/users`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(user => this.buildUser(user))
      ));
  }

  saveUser(user: any): Observable<any> {
    const url = `/api/UserEntity/user`;
    return new Observable<any>(subscriber => this.http.post(url, user)
      .subscribe(value =>  subscriber.next(this.buildUser(value)),
        error => subscriber.error(error)));
  }

  buildUser(user): User {
    return new User({
      email: user?.email,
      userRoles: user?.userRoles,
      name: user?.name,
      id: user?.id,
      accountStatus: user?.accountStatus,
      office: user?.office,
      officeId: user?.officeId
    });
  }

  deleteUserRole(id: any): Observable<any> {
    const url = `/api/UserRoleEntities/${id}`;
    return this.http.delete(url);
  }

  saveUserRole(role: UserRole): Observable<any> {
    const url = `/api/UserRoleEntities`;
    return this.http.post(url, role);
  }

  getBreTypes(): Observable<any> {
    const url = `/api/BreEntities`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(breType => {
          return new Bre({
            id: breType.id,
            breCode: breType.breCode,
            breDescription: breType.breDescription
          });
        })
      ));
  }

  getRoles(): Observable<any> {
    const url = `/api/RoleEntities`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(role => {
          return new Role({
            id: role.id,
            roleCode: role.roleCode,
            roleDescription: role.roleDescription
          });
        })
      ));
  }

  deleteBre(id): Observable<any> {
    const url = `/api/BreEntities/${id}`;
    return this.http.delete(url);
  }

  saveBre(bre): Observable<any> {
    const url = `/api/BreEntities`;
    return this.http.post(url, bre);
  }

  getTransactions(dateFrom, dateTo, bres, statuses, documentName, creator, recipient): Observable<any> {
    const breUri = 'breCodes=' + (bres as string[]).join('&breCodes=');
    const statusUri = 'statuses=' + (statuses as string[]).join('&statuses=');
    console.log('$$$$$$$$$$$$' + breUri);
    const url = `/api/TransactionEntities?dateFrom=${dateFrom}&dateTo=${dateTo}&${breUri}&${statusUri}&documentName=${documentName}&creator=${creator}&recipient=${recipient}`;
    return this.http.get(url).pipe(map((value: any[]) => value.map(transaction => {
      return new Transaction({
        id: transaction.id,
        userEmail: transaction.userEmail,
        recipients: transaction.recipients,
        status: transaction.status,
        sysCreate: transaction.sysCreate,
        transactionDocuments: transaction.transactionDocuments,
        office: transaction.office,
        additionalDocuments: transaction.additionalDocuments
      });
    })));
  }

  getTransaction(transactionId): Observable<any> {
    const url = `/api/TransactionEntities/${transactionId}`;
    return this.http.get(url);
  }


  getTransactionDocument(documentId): Observable<any> {
    const url = `/api/TransactionDocumentEntities/${documentId}`;
    return this.http.get(url);
  }

  getTransactionDocumentPreview(transactionDocument): Observable<any> {
    const url = `/api/DocumentPreview/getTransDocPreview`;
    return this.http.post(url, transactionDocument, {responseType: 'blob'});
  }

  changeUserActivation(users: User[], accountStatus: string): Observable<any> {
    const url = `/api/UserEntity/changeUserActivation`;
    const ids = users.map(user => {
      return user.id;
    });
    const data = {
      ids,
      accountStatus
    };
    return this.http.post(url, data);
  }

  getUsersNeedActivationCount(): Observable<any> {
    const url = `/api/UserEntity/getUsersNeedActivationCount`;
    return this.http.get(url);
  }

  getGlobalFields(): Observable<any> {
    const url = `/api/GlobalFieldEntities`;
    return this.http.get(url)
      .pipe(map( (value: any[]) => value.map(globalField => {
        return new GlobalField({
          id: globalField.id,
          name: globalField.name,
          description: globalField.description,
          visible: globalField.visible
        });
      })));
  }

  cancelTransaction(id): Observable<any> {
    const data = {
      id
    };
    const url = `/api/TransactionEntities/cancelTransaction`;
    return this.http.post(url, data);
  }

  cancelTransactionDocument(id: string): Observable<any> {
    const data = {
      id
    };
    const url = `/api/TransactionDocumentEntities/cancelTransactionDocument`;
    return this.http.post(url, data);
  }


  isDocumentExists(documentCode): Observable<any> {
    const url = `/api/DocumentFormEntities/isDocumentExists/${documentCode}`;
    return this.http.get(url);
  }

  getOffices(): Observable<any> {
    const url = `/api/OfficeEntities`;
    return this.http.get(url)
      .pipe(map( (value: any[]) => value.map(office => {
        return new Office({
          id: office.id,
          name: office.name,
          code: office.code,
          hasLtssBre: office.hasLtssBre
        });
      })));
  }

  saveOffice(office: any): Observable<any> {
    const url = `/api/OfficeEntities`;
    return this.http.post(url, office);
  }

  deleteOffice(id): Observable<any> {
    const url = `/api/OfficeEntities/${id}`;
    return this.http.delete(url);
  }

  addOfficeToUser(userId, officeId): Observable<any> {
    const url = `/api/UserEntity/addOfficeToUser`;
    const data = {
      userId,
      officeId
    };
    return this.http.post(url, data);
  }

  moveFieldUp(id): Observable<any> {
    const url = `/api/DocumentFormFieldEntities/moveFieldUp/${id}`;
    return this.http.post(url, id);
  }

  moveFieldDown(id): Observable<any> {
    const url = `/api/DocumentFormFieldEntities/moveFieldDown/${id}`;
    return this.http.post(url, id);
  }

  documentsByUser(): Observable<any> {
    const url = `/api/Reports/documentsByUser`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(result => {
        return new CountReport({
          value: result.quantity,
          name: result.name
        });
      })));
  }
  documentsQuantity(): Observable<any> {
    const url = `/api/Reports/documentsQuantity`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(result => {
        return new CountReport({
          value: result.quantity,
          name: result.name
        });
      })));
  }

  documentsPerDay(): Observable<any> {
    const url = `/api/Reports/documentsPerDay`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(result => {
        return new CountReport({
          value: result.quantity,
          name: result.name
        });
      })));
  }

  documentsByOffice(): Observable<any> {
    const url = `/api/Reports/documentsByOffice`;
    return this.http.get(url)
      .pipe(map((value: any[]) => value.map(result => {
        return new CountReport({
          value: result.quantity,
          name: result.name
        });
      })));
  }

  uploadFile(transactionId, file: File): Observable<any> {
    const url = `/api/AdditionalDocumentEntities/${transactionId}`;
    const formData = new FormData();
    formData.append('file', file);
    /*const data = {
      fileName: file.name,
      fileSize: file.size,
      transaction
    };
    formData.append('transactionId', new Blob([JSON.stringify(transaction)], {type: 'application/json'}));*/
    return this.http.post<any>(url, formData, {headers: new HttpHeaders ({})/*, reportProgress: true, observe: 'events' */});
  }

  getAdditionalDocumentPreview(id): Observable<any> {
    const url = `/api/AdditionalDocumentEntities/getAdditionalDocumentPreview/${id}`;
    return this.http.get(url, {responseType: 'blob'});
  }

  saveUserRoles(userId: string, roles: Role[]): Observable<any> {
    const url = `/api/UserRoleEntities/saveUserRoles`;
    const data = {
      userId,
      roles
    };
    return this.http.post(url, roles);
  }

  sendWelcomeEmail(id): Observable<any> {
    const url = `/api/UserEntity/sendWelcomeEmail/${id}`;
    return this.http.get(url);
  }
}
